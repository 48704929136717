import ContentFit from 'components/Atoms/ContentFit';
import PanelLimit from 'components/Organisms/PanelLimit';
import PanelSelect from 'components/Organisms/PanelSelect';
import HeaderContext from 'providers/HeaderProvider';
import React, { useContext, useEffect } from 'react';

export default function products() {
	const { setHeader } = useContext(HeaderContext);

	useEffect(() => {
		setHeader({ isMain: false, isSelect: false, isLimit: false });
	}, []);

	return (
		<div className='h-full flex flex-col items-center justify-between px-20'>
			<p className='pt-05 pb-10'>Dowiedz się więcej o naszych produktach. Wybierz rodzaj faktoringu, który Cię interesuje I przejdź do szczegółów.</p>

			<ContentFit siblingHeight='14rem' animated>
				<div className='panel-wrapper portrait:px-20 flex-4/1'>
					<PanelSelect />
					<PanelLimit />
				</div>
			</ContentFit>
		</div>
	);
}
