import React from 'react';
import { useWindowSize } from 'react-use';
import { css, styled } from 'twin.macro';

const StyledContent = styled.div`
	${({ animated }) => animated && css`transition: min-height 0.2s;`}
	
	@media (orientation: portrait) {
		${({ size, siblingHeight, navHeight }) => size && css`min-height: calc(${size.height}px - ${siblingHeight} - ${navHeight})`};
	}

	@media (orientation: landscape) {
		${({ size, siblingHeight }) => size && css`min-height: calc(${size.height}px - ${siblingHeight})`};
	}
	`;

export default function ContentFit({ siblingHeight, navHeight, children, ...props }) {
	const size = useWindowSize();

	return (
		<StyledContent
			className='w-full flex flex-col items-center justify-between'
			siblingHeight={siblingHeight}
			navHeight={navHeight}
			size={size}
			{...props}
		>
			{children}
		</StyledContent>
	);
}

ContentFit.defaultProps = {
	siblingHeight: '0vw',
	navHeight: '8rem'
};
